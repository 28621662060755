module.exports = {
    general: {
        pleaseSelect: 'Please select',
        noneSelected: 'None selected',
        delete: 'Delete',
        edit: 'Edit',
        cancel: 'Cancel',
        loadingData: 'Loading data from server...',
        confirm: 'Confirm',
        rememberMyChoice: 'Remember my choice',
        yes: 'Yes',
        no: 'No',
        ok: 'OK',
        date: 'Date',
        clickHere: 'Click here',
        attention: 'Attention!',
        view: 'View',
        name: 'Name',
        add: 'Add',
        save: 'Save',
        back: 'Back',
        next: 'Next',
        unknown: 'Unknown',
        expand: 'Expand',
        collapse: 'Collapse',
        close: 'Close',
        email: 'E-mail',
        discard: 'Discard',
        share: 'Share',
        saveChanges: 'Save changes',
        loadingApp: 'Loading app...',
        working: 'Busy',
        optional: 'Optional',
        statusInspection: 'Inspection',
        statusAway: 'Away',
        statusDiscarded: 'Discarded',
        statusAcceptance: 'Acceptance',
        statusOkForNow: 'OK for now',
        submit: 'Submit',
        noItemsToShow: 'There are no items to show.',
        rename: 'Rename',
        emailAddress: 'E- mail address',
        showAll: 'Show all {0}',
        showLess: 'Show less',
        none: 'None',
        previous: 'Previous',
        savingData: 'Saving items...',
        moreOptions: 'More options',
        selectAll: 'Select all',
        deselectAll: 'Deselect all',
        typeHere: 'Type here...',
        users: 'users'
    },
    configurator: {
        configurator: 'Configurator',
        configure: 'Configure',
        noRibbons: 'This model has no ribbons to configure',
        colorWarning: 'Product color may vary due to photographic lighting and monitor settings.',
        selectedColor: 'Selected color:',
        ribbonPreviewTip: 'For an accurate ribbon color and pattern preview, please see the top right corner.',
        material: 'Material',
        lengthAdjustment: 'Length adjustment',
        noAdjustment: 'No adjustment',
        extraOverlap: 'Extra overlap',
        monogramLine: 'Monogram text, line',
        monogramStitchcolor: 'Monogram stitch color',
        monogramStitchcolorWarning: 'If the chosen color is not available, the factory may choose a similar color.',
        monogramVelcro: 'Monogram on Velcro',
        armholeAdjustment: 'Arm hole adjustment',
        customization: 'Customization',
        protectiveMaterial: 'Protective material'
    },
    quotation: {
        quotationOverview: 'Quotation overview',
        thanksForRequest: `Thank you for your quote request! You will receive a confirmation of your quote request via e-mail. We will process your quote as soon as possible. If you have any other questions, please `,
        contactSales: 'contact Sales & Logistics.',
        emptyCart: 'Your quotation is empty, start with configuring an item',
        total: 'Total',
        configureAnother: 'Configure another',
        requestQuotation: 'Request quotation',
        configureYourModel: 'Configure your {0}',
        skirtIdentical: 'Skirt identical',
        vest: 'Vest',
        skirt: 'Skirt',
        urlCopied: 'URL copied to clipboard.',
        quantity: 'Quantity',
        addToQuote: 'Add to quote',
        formIncomplete: 'All sections, except "Customization" are mandatory.',
        include: 'Include',
        favoriteSaved: 'Your favourite has successfully been saved',
        goToFavorites: 'Go to favourites',
        favoriteSavingFailed: 'Favourite  could not be saved, try again later',
        shareTitle: 'Ray iQ Configurator',
        shareText: 'Check out my custom lead apron in Ray iQ',
        comments: 'Questions/comments'
    },
    addEditApron: {
        manufacturer: 'Manufacturer',
        model: 'Model',
        serial: 'Serial number',
        size: 'Size',
        fabricColor: 'Fabric color',
        ribbonColor: 'Ribbon color',
        selectModelFirst: 'Select a model first',
        notAvailable: 'No {0} avaible for this model',
        fabrics: 'fabrics',
        ribbons: 'ribbons',
        importDate: 'Imported on',
        productionDate: 'Production date',
        nameOfUser: 'Name of user',
        department: 'Department | Departments',
        manageDepartments: 'Manage your departments on the',
        settingsPage: 'settings page',
        monogram: 'Monogram | Monograms ' ,
        line1: 'Line 1',
        pbEquivalence: 'Pb equivalence',
        front: 'Front',
        back: 'Back',
        uncategorized: 'Uncategorized',
        apronsSkirtsVests: 'Aprons, vests & skirts',
        thyroidCollarsOthers: 'Thyroid collars, glasses & others',
        multipleSelected: 'Multiple {0} selected, type to overwrite',
        multipleModelsSelected: 'Multiple models selected',
        multipleManufacturesSelected: "Multiple manufacturers are selected",
        multipleSizesSelected: "Multiple sizes selected. Change to overwrite",
        multipleUsersSelected: "Multiple users selected, type to overwrite",
        multipleDepartmentSelected: "Multiple departments selected",
        multipleColorsSelected: "Multiple colors selected",
        multipleMonogramsSelected: "Multiple monograms, type to overwrite",
        differentValuesSelected: "Multiple values selected",
        onlyAvaibleForScanFlex: "Only available for Scanflex items",
        exampleName: "Example: John Doe",
        exampleDepartment: "Example: Radiology",
        notAvailableForModel: 'Not available for this model',
        ModelConvertedToDefault: 'Due to a change of manufacturer, some models have been reset to default "other"',
        inventoryNumber: 'Inventory number',
        suggested: 'Suggested',
        alphabetical: 'Alphabetical'

    },
    confirmBackToInventory: {
        areYouSure: 'Are you sure that you want to move these discarded items back to the inventory list?',
    },
    confirmDiscard: {
        areYouSure: "Are you sure that you want to discard the following items? These items will be moved to the Discarded Items list and are no longer visible to your colleagues."
    },
    imageList: {
        loadingImages: 'Loading images...'
    },
    reportOverview: {
        reportOverview: 'Report Overview',
        apronDetails: 'Item details',
        loadingApronData: 'Loading item data...',
        history: 'History',
        show: 'Show',
        baseline: 'Baseline',
        protection: 'Protection',
        hygiene: 'Hygiene',
        fasteners: 'Fasteners',
        reports: 'Report | Reports',
        notes: 'Notes',
        photos: 'Photos',
        noReports: 'This item has no reports filed.',
        fileFirstReport: 'to file the first report for this item.',
        areYouSureDelete: 'Are you sure you want to delete this report? This can not be undone.',
        deleteFailed: 'The report could not be deleted.',
        viewImages: 'View images'
    },

    textWithDatepicker: {
        title: 'Next inspection date',
        lessYearWarning: 'The next inspection date must be less than a year away.',
    },
    manageDepartments: {
        noOfAprons: 'No. of items',
        addDepartment: 'Add a department',

        areYouSureDelete: 'Are you sure you want to delete the {0} department? This will not delete the items assigned to the department.',
        ownerWarning: 'Only the "owner" of an institution can manage the departments in Ray iQ. The owner of your institution in Ray iQ is {0}',
    },
    apronShortlist: {
        settings: 'Settings',
        status: 'Status',
        sn: 'S/N',
        fabric: 'Fabric',
        ribbon: 'Ribbon',
        user: 'User',
        lastInspection: 'Last inspection',
        awayForRepairs: 'Away for repairs',
        noResults: 'Your search returned no results',
        defaultEmptyListText: 'There are no items in your inventory.',
        fileReportForApron: 'Would you like to file a report for this item that just returned from repairs?',
        inventoryNumber: 'Inventory No',
        nextInspection: 'Next inspection'
    },
    searchBox: {
        placeholder: 'Search...',
    },
    tableFilters: {
        filterHeading: 'Filter Inventory',
        size: 'Size',
        status: 'Status',
        model: 'Model',
        manufacturer: 'Manufacturer',
        importDate: 'Imported on',
        nextInspection: 'Next inspection',
        user: 'User',
        department: 'Department',
        awayForRepairs: 'Away for repairs',
        monogram: 'Monogram',
    },
    tableSettings: {
        columns: 'Columns',
        chooseColumns: 'Choose which of the following optional columns must be shown/hidden:',
        tableSettings: "Table settings"
    },
    apronDetail: {
        sizeAdjustments: 'Size adjustments',
        leadEquivalence: 'Lead equivalence',
    },
    previewPlusDetails: {
        apronNotFound: 'Item not found. Please {0}.',
        returnToDashboard: 'return to the dashboard',
        fabricColorUnknown: 'Fabric color unknown',
        fabricAndRibbonColorUnknown: 'Fabric and ribbon color unknown',
        ribbonColorUnknown: 'Ribbon color unknown',
        editDetails: 'Edit details'
    },
    questionnaire: {
        veryBad: 'Very bad',
        bad: 'Bad',
        acceptable: 'Acceptable',
        good: 'Good',
        veryGood: 'Very good',
        protectiveQualitiesInstruction: 'Check whether the protective materials have defects using the fluoroscopic mode of an x-ray device. Both the size and the location of the defect are important. Based on the x-ray images you can decide to discard, approve or place the apron under close observation.',
        hygieneInstruction: 'Visually inspect the clothing and decide whether the item can be properly cleaned. Pay extra attention to damage of the outer material. Also inspect difficult to clean areas, such as the ribbons and seams. ',
        fastenerInstruction: 'Test whether the Velcro closes securely and does not release too easily. Also make sure the stitching around the Velcro is secure. If the apron has buckles, make sure these are in good shape.',
    },
    quoteDetail: {
        sizing: 'Sizing',
        garmentSize: 'Garment size',
        customization: 'Customization',

    },
    readMore: {
        readMore: 'Read more'
    },
    inviteColleague: {
        addAcolleague: 'Add a colleague',
        welcomeText: 'Invite a colleague to join your institution {0}.',
        accepted: 'Accepted',
        pending: 'Pending',
        ownerWarning: 'Only the "owner" of an institution can invite new team members and edit user rights. The owner of your instition in Ray iQ is {0}',
        invitationSent: 'The invitation was sent.',
        rights: 'Rights',
        editUserRights: 'Edit user rights',
        sendInvite: 'Send invitation',
        areYouSureDelete: 'Are you sure you want to delete the user {0}?',
        owner: 'Institution owner'
    },
    accountConfig: {
        welcome: 'Welcome',
        thankYou: 'Thank you for registering. To make the most of Ray iQ, this wizard will take you through the set up of your account.',
        initialStatus: 'Initial status for new items',
        initialStatusQuestion: 'Would you like to perform an inspection on every new item entered in the database? (This setting can later be changed on the Settings page.)',
        tolerances: 'Tolerances',
        toleranceInstruction: 'Set the tolerance for the performance of your items.',
        toleranceInfoMessage: 'This will help you decide whether or not to approve an item when making reports. Every item that scores the selected values (or higher) will be approved.',
        protectiveQualities: 'Protective qualities',
        conditionFasteners: 'Condition of fasteners',
        itemLimitReached: 'You have reached the inventory limit of {0} items.'
    },
    dashboardHome: {
        dashboard: 'Dashboard',
        numberOfItems: 'Total number of items',
        inspectionItems: 'Items that need inspection',
        importItems: 'Items ready for import',
        inspectionListHeader: 'Items that require inspection/maintenance',
        emptyInspectionList: 'There currently are no items that need attention.',
        myInventory: 'My inventory',
        noItemsWarning: 'You have no items in your inventory. Use the Add item button to add an item.',
        importScanflexTip: 'Use the Import button on the top left hand side to import your Scanflex purchases to the inventory.',
        import: 'Import',
        accountConfig: 'Account Configuration',
        editApron: 'Edit item',
        addApron: 'Add item',
        discardItems: 'Discard item(s)',
        importFailed: 'The import could not de completed. Please contact us via info@ray-iq.com',
        bulkEditAprons: 'Bulk edit',
        inventoryLimitReached: 'Inventory limit reached!'

    },
    privateMenu: {
        discardedItems: 'Discarded Items',
        logOut: 'Log out',

    },
    productSelector: {
        selectProduct: 'Select product',
        frontProtection: 'Front protection',
        allRoundProtection: 'All-round protection',
        thyroidCollars: 'Thyroid collars',
        popularChoice: 'Populair <br /> choice!',
        open: 'Open',
        areYouSureDeleteFavorite: 'Are you sure you want to delete {0} from favourite configurations?',
        added: 'Added',
        colorScheme: 'Color scheme',
        renameFavorite: 'Give your favorite configuration a new name',
        renameFavoriteTitle: 'Give your favorite configuration a new name',
        nameFavorite: 'Give your favorite configuration a name, save it, and find your configuration back in the product overview.',
        enterName: 'Enter a name...'
    },
    reportPage: {
        outOfToleranceWarning: 'Your {0} is out of tolerance',
        notesAndPhotos: 'Notes and photos',
        uploading: 'Uploading...',
        upload: 'Upload',
        fileRestirctions: 'JPG, PNG or BMP. Maximum 5 photos and 4MB per photo',
        currentFiles: 'Currently selected files',
        noToleranceMessage: 'You must set up tolerance criteria first before reports can be saved. Visit the {0} to configure tolerance criteria.',
        discardApron: 'Discard item',
        outOfToleranceReminder: 'This item is out of tolerance on the following criteria',
        approveAnywayMessage: 'Based on this outcome, the item should be discarded. If there is no replacement yet, you can approve this item anyway to keep it in your inventory until its replacement becomes avaialble.',
        keepInInventory: 'Keep in inventory',
        keptInInventoryMessage: 'You have chosen to keep this item in the inventory even though it is out of tolerance. It is advised to schedule an inspection within a short timeframe.',
        scheduleNextInspection: 'Schedule next inspection',
        narrowestOfMargins: 'This item was accepted with the narrowest of margins. It is recommended to keep a close watch on this item. You can schedule the next inspection here.',
        surplusIgnored: 'You have selected more than five photos. The surplus will not be uploaded.',
        fileTooLarge: 'File "{0}" is too large and will be skipped.',
        compressedArchves: 'Compressed archives are not allowed. File "{0}" will be skipped.'
    },
    settings: {
        account: 'Account',
        general: 'General',
        currentPassword: 'Current password',
        newPassword: 'New password',
        confirmPassword: 'Confirm password',
        inspectionNeeded: 'Inspection needed',
        afterRepairs: 'Status for items returned after repairs',
        emailNotifications: 'E-mail notifications',
        inspectionReminders: 'Reminders for inspection',
        newsUpdates: 'News updates (features etc.)',
        inviteColleague: 'Invite colleague',
        passwordUpdateError: 'Your password could not be updated for the following reason(s):',
        passwordsDontMatch: 'The new password and the confirmation do not match.',
        passwordCriteria: 'The password must be at least 8 characters long, should contain one capital letter, one digit, one lower case letter and one special character ($!%*?&).',
        emailMayNotBeEmpty: 'The e-mail field may not be empty.',
        afasReminders: 'Purchased item import reminders',
        saveMessage: 'All settings (except notification settings) are global. These affect your whole institution.',
        FiveThreePointScore: 'Five-point scoring system'
    },
    worklist: {
        worklist: 'Worklist',
        reportSaved: 'Report saved',
        reportSavedSuccessfully: 'The report has been saved successfully. To make another report, pick another item from the worklist.',
        discardedMessage: 'Your {0} is discarded. You can configure a new item in the {1}',
        noItemsNeedAttention: 'There currently are no items that need attention.'
    },
    passwordFields: {
        password: 'Password',
        fillPasswordPlease: 'Please fill out a password'
    },
    confirmEmail: {
        confirmationFailed: 'E-mail confirmation failed',
        contactUsToResolve: 'Your e-mail could not be confirmed. Please contact us via info@ray-iq.com to resolve this issue.',
        visitHomePage: 'Click here to visit the home page.',
        confirmingEmail: 'Confirming e-mail address',
        pleaseWaitConfirm: 'Please wait while we confirm your e-mail address.',
        confirmationSuccess: 'E-mail address confirmed',
        redirectInProgress: 'You will now be redirected to the login form.',
        forceRedirect: 'Click here if you are not being redirected automatically.'
    },
    contact: {
        contactUs: 'Contact us',
        suggestionsAndIssues: 'If you have suggestions for the application, or if you run into issues please contact:',
        salesAndLogistics: 'Sales & Logistics',
        salesRelatedQuestions: 'If you have any sales-related questions <span class="color-red">(BeNeLux countries only!)</span> please contact:',
        ourLocation: 'Our location',
        theNetherlands: 'The Netherlands'
    },
    forgotPassword: {
        forgotPassword: 'Forgot password',
        enterEmail: 'Enter your e-mail address to generate a new password.',
        checkEmailMessage: 'Check your e-mail and click on the link to reset your password.'
    },
    landingspage: {
        intro: "Ray iQ (developed by Cablon Medical ) is a web-app designed to make annual reporting and administration of radiation protection items easier and more organized. The app sends you automatic reminders for the annual checks of radiation protection items. Reports are easily filed per item. Large and complicated Excel files are no longer needed. An apron configurator is included, which can be used to configure your future radiation protection outfit.",
        KeepTrackTitle: 'Keep track of your lead aprons',
        HowToGetStarted: 'How to get started',
        packedWithFeatures: 'Packed with features',
        easyReporting: 'Easy reporting',
        automaticReminders: 'Automatic reminders',
        apronConfigurator: 'Apron Configurator',
        rayiqAvailabilityMessage: 'Ray iQ is available to all Cablon Medical customers with exclusive agreements for the purchase of Scanflex lead aprons and accessories. Interested? Contact your account manager or Cablon Medical.',
        call: 'Call',
        mail: 'Mail',
        tryConfigurator: 'Try configurator for free',
        rayiqExplained: 'Ray iQ explained',
        easyImport: 'Easy import',
        upToDateInventory: 'Up-to-date inventory',
        easyImportText: 'All your purchased Scanflex items are automatically included in Ray iQ',
        easyReportingText: 'Simple reporting with adjustable rejection criteria for annual checks',
        automataticRemindersText: 'Automatic reminders for annual checks',
        apronConfiguratorText: 'Compose new clothing and request quotes in the configurator',
        upToDateInventoryText: 'Up-to-date overview of all your radiation protection items'
    },
    login: {
        login: 'Log in',
        register: 'Register',
        createAccount: 'Create account',
        forgotPassword: 'Forgot your password?'
    },
    publicMenu: {
        toDashboard: 'To Dashboard',
    },
    resetPassword: {
        resetPassword: 'Reset password',
        enterPassword: 'Enter the new password you wish to use for your account.',
        resetFailed: 'Password reset failed',
        resetFailedExplanation: 'Your password could not be reset. Note: Password reset links can only be used once.',
        clickHereTryAgain: 'Click here to try again.',
        resetSuccessful: 'Password reset successfull',
        loginAndRedirectPending: 'You will now be logged in and be redirected to the dashboard.'
    },
    discardedItems: {
        backInInventory: 'Move to inventory'
    },
    importFromExcel: {
        title: 'Import items from Excel template',
        stepOne: 'Download the template and fill the template with your data. If you already have a valid template with data, press next.',
        downloadTemplate: 'Download template',
        stepTwo: 'Drop the Excel template in the area below or press the upload button to select the Excel template from your device',
        dropTemplate: 'Drop Template here',
        acceptedFiles: '(Only XLS or XLSX files. Maximum file size: 4MB.)',
        tooManyFiles: 'You can only upload only one file at a time',
        nonValidExtention: 'Not a valid file',
        fileTooLarge: 'File {0} is too large. Please use upload data in smaller batches.',
        releaseMouseBtn: 'Release mouse button to drop template',
        processingExcel: 'Excel is being processed',
        maxRows: 'Up to 1000 rows can be processed at a time',
    },
    compareImportedData: {
        title: 'Import Excel',
        manufacturerConverted: 'Manufacturer (Converted)',
        modelConverted: 'Model (Converted)',
        addToInventory: 'Add to inventory',
        errors: 'Incomplete',
        converted: 'Needs verification',
        changeManufacturerName: 'Would you like to change all items called {0} to {1}?',
        itemSkipped: 'Skipped {n} item, as the serial number already existed | Skipped {n} items, as these serial numbers already existed',
        itemDuplicate: 'Ignored {n} duplicate entry in the Excel sheet | Ignored {n} duplicate entries in the Excel sheet',
        largeExcel: 'Skipped {n} item, as the uploaded Excel file exceeds 1000 rows | Skipped {n} items, as the uploaded Excel file exceeds 1000 rows.',
        addingItems: 'Adding items to your inventory. Please do not close this window.',
        addingComplete: '{n} items have been added to your inventory. You can view them on your dashboard. You will be redirected to the dashboard within a few seconds. | {n} items have been added to your inventory. You can view them on your dashboard. You will be redirected to the dashboard within a few seconds.',
        errorsDuringAdd: '{a} item(s) were added successfully. {n} item could not be added. Please try again. If you continue to have issues, please contact us via | {a} item(s) were added successfully. {n} items could not be added. Please try again. If you continue to have issues, please contact us via ',
        limitExceeded: 'Skipped {0} items because the inventory limit is reached'
    },
    cookieNotice: {
        message: 'By logging in, you accept functional cookies.',
    },
    departmentRights: {
        rightsDescription: 'When radiation protection items are assigned to departments, you can limit which user can see the items of which department(s) using the checkboxes below.'
    },
    inviteRights: {
        atLeastOneDept: 'You must grant rights to at least one department.',
        emailColleague: 'Email address of colleague'
    },
    pageNotFound: {
        title: " Page not found...",
        message: "Sorry, the page you are looking for doesn't exist or has been moved.",
        buttonText: "Go to homepage"
    },
    freeTextInput: {
        errorMessage: 'This field may not be left empty',
    },
    testimonials: {
        title: 'What others say',
        functionTitle0: 'Head of Radiation Physics & Health Physics',
        functionTitle1: 'Supervisory radiation expert',
        testimonial0: `Compared to our previous, simple, inventory list, the Ray iQ application gives us a clear dashboard with an overview of all radiation protection equipment, plus a separate list of items that are due for inspection. The visual approach and the ease of use resulted in a fluent transition to this new application.

Our organization experiences the application as an improvement for, among other things, the following reasons:

Each department can visualize their own list of items, while the responsible person retains an overview of all items in the organization.
The pass/fail criteria are configurable per organization.
In the configurator, favourite garment configurations can be created, so each department orders the same fabric colour for the same garment size. This saves time when choosing the right radiation protection garment.
The application creates a history of reports for each time, which <mark>gives insight in the degradation of the item over time, and standardizes the decision making when discarding items.</mark>
The monthly e-mail with an overview of items that require inspection is found useful.`,

        testimonial1: `We have been using Ray iQ for the inspections of our radiation protection garments. What a relief!

All items are shown in a clear database and <mark>in a few clicks an inspection report is made</mark>, which advises whether to keep or reject an item.

Rejected items can be replaced in an instant by ordering new aprons through the configurator.

All in all, a big improvement thanks to Ray iQ.`,
    }
}

//Meervoud
//{{ $tc('addEditApron.department', 2) }}


//Enkelvoud
//{{ $t('inviteRights.atLeastOneDept') }}


// in Methods:
//this.$t('importFromExcel.toManyFiles')
