module.exports = {
	general: {
		pleaseSelect: 'Veuillez sélectionner',
		noneSelected: 'Aucun sélectionné',
		delete: 'Supprimer',
		edit: 'Modifier',
		cancel: 'Annuler',
		loadingData: 'Chargement des données à partir du serveur en cours... ',
		confirm: 'Confirmer',
		rememberMyChoice: 'Mémoriser mon choix',
		yes: 'Oui',
		no: 'Non',
		ok: 'OK',
		date: 'Date',
		clickHere: 'Cliquer ici',
		attention: 'Attention !',
		view: 'Visualiser',
		name: 'Nom',
		add: 'Ajouter',
		save: 'Enregistrer',
		back: 'Précédent',
		next: 'Suivant',
		unknown: 'Inconnu',
		expand: 'Étendre',
		collapse: 'Réduire',
		close: 'Fermer',
		email: 'E-mail',
		discard: 'Éliminer',
		share: 'Partager',
		saveChanges: 'Enregistrer les modifications',
		loadingApp: 'Chargement de l’application en cours... ',
		working: 'Occupé',
		optional: 'Optionnel',
		statusInspection: 'Inspection',
		statusAway: 'Absent',
		statusDiscarded: 'Éliminé',
		statusAcceptance: 'Acceptation',
		statusOkForNow: 'OK pour le moment',
		submit: 'Présenter',
		noItemsToShow: 'Il n’y a pas d’articles à afficher.',
		rename: 'Renommer',
		selectAll: 'Tout sélectionner',
		deselectAll: 'Tout déselectionner',
		typeHere: 'Écrivez ici',
		moreOptions: "Plus d'options",
		savingData: 'Sauvegarde des articles...',
		none: 'Aucun',
		previous: 'Précédent',
		showAll: 'Afficher tout {0}',
		showLess: 'Afficher moins',
		users: 'utilisateurs'
	},
	configurator: {
		configurator: 'Configurateur',
		configure: 'Configurer',
		noRibbons: 'Ce modèle n’a pas de lisérés à configurer',
		colorWarning: 'La couleur des produits peut varier en raison de l’éclairage photographique et des réglages du moniteur.',
		selectedColor: 'Couleur sélectionnée :',
		ribbonPreviewTip: 'Pour une prévisualisation précise de la couleur du liséré et du motif, voir le coin supérieur droit.',
		material: 'Matériau',
		lengthAdjustment: 'Ajustement en longueur',
		noAdjustment: 'Pas d’ajustement',
		extraOverlap: 'Chevauchement supplémentaire',
		monogramLine: 'Texte du monogramme, ligne',
		monogramStitchcolor: 'Couleur de point de monogramme',
		monogramStitchcolorWarning: `Si la couleur choisie n'est pas disponible, l'usine peut choisir une couleur similaire.`,
		monogramVelcro: 'Monogramme sur velcro',
		armholeAdjustment: 'Ajustement des ouvertures pour les bras',
		customization: 'Personnalisation',
		protectiveMaterial: 'Matériau de protection'
	},
	quotation: {
		quotationOverview: 'Vue d’ensemble du devis',
		thanksForRequest: 'Merci pour votre demande de devis ! Vous allez recevoir une confirmation de votre demande de devis par e-mail. Nous traiterons votre devis dans les plus brefs délais. Si vous avez d’autres questions, veuillez ',
		contactSales: 'contacter Ventes et Logistique.',
		emptyCart: 'Votre devis est vide, veuillez commencer par configurer un article',
		total: 'Total',
		configureAnother: 'Configurer un autre',
		requestQuotation: 'Demander un devis',
		configureYourModel: 'Configurer votre {0}',
		skirtIdentical: 'Jupe identique',
		vest: 'Gilet',
		skirt: 'Jupe',
		urlCopied: 'URL copiée dans le presse-papiers.',
		quantity: 'Quantité',
		addToQuote: 'Ajouter au devis',
		formIncomplete: 'Toutes les sections, sauf « Personnalisation », sont obligatoires.',
		include: 'Inclus',
		favoriteSaved: 'Votre favori a été enregistré avec succès',
		goToFavorites: 'Aller aux favoris',
		favoriteSavingFailed: "Le favori n'a pas pu être enregistré, réessayez plus tard",
		shareTitle: 'Ray iQ Configurateur',
		shareText: 'Découvrez mon tablier de plomb personnalisé dans Ray iQ',
		comments: 'Questions/commentaires'
	},
	addEditApron: {
		manufacturer: 'Fabricant',
		model: 'Modèle',
		serial: 'Numéro de série',
		size: 'Taille',
		fabricColor: 'Couleur du tissu',
		ribbonColor: 'Couleur du liséré',
		selectModelFirst: 'Sélectionner d’abord un modèle',
		notAvailable: 'Pas de {0} disponible pour ce modèle',
		fabrics: 'tissus',
		ribbons: 'lisérés',
		importDate: 'Importé sur',
		productionDate: 'Date de production',
		nameOfUser: 'Nom de l’utilisateur',
		department: 'Département | Départements',
		manageDepartments: 'Gérer vos départements sur la',
		settingsPage: 'page des paramètres',
		monogram: 'Monogramme',
		line1: 'Ligne 1',
		pbEquivalence: 'Équivalence Pb',
		front: 'Devant',
		back: 'Dos',
		uncategorized: 'Aucune catégorie',
		apronsSkirtsVests: 'Tabliers, vestes et jupes',
		thyroidCollarsOthers: 'Caches thyroïdes, lunettes & autres',
		multipleSelected: 'Plusieurs {0} sélectionnés, tapez pour écraser',
		multipleModelsSelected: 'Plusieurs modèles sélectionnés',
		multipleManufacturesSelected: "Plusieurs fabricants sélectionnés",
		multipleSizesSelected: "Plusieurs tailles sélectionnées",
		multipleUsersSelected: "Plusieurs utilisateurs sélectionnés, remplacez-les par une autre saisie",
		multipleDepartmentSelected: "Plusieurs départements sélectionnés",
		multipleColorsSelected: "Plusieurs couleurs sélectionnées",
		multipleMonogramsSelected: "Plusieurs monogrammes sélectionnés, remplacez-les par une autre saisie",
		differentValuesSelected: "Valeurs différentes sélectionnées",
		onlyAvaibleForScanFlex: "Uniquement disponible pour les articles Scanflex",
		ModelConvertedToDefault: "En raison d'un changement de fabricant, certains modèles ont repris la valeur par défaut « autre »",
		exampleName: "Exemple : Jean Dumont",
		exampleDepartment: "Exemple : Radiologie",
		inventoryNumber: "Numéro d'inventaire",
		suggested: 'Suggéré',
		alphabetical: 'Alphabetique'
	},
	confirmBackToInventory: {
		areYouSure: 'Voulez-vous vraiment retransférer ces articles éliminés à la liste des articles en stock ?',
	},
	confirmDiscard: {
		areYouSure: "Voulez-vous vraiment transférer ces articles à la liste des articles éliminés ?"
	},
	imageList: {
		loadingImages: 'Chargement des images en cours... '
	},
	reportOverview: {
		reportOverview: 'Vue d’ensemble du rapport',
		apronDetails: 'Informations sur l’article',
		loadingApronData: 'Chargement des données sur l’article en cours... ',
		history: 'Historique',
		show: 'Afficher',
		baseline: 'Base de référence',
		protection: 'Protection',
		hygiene: 'Hygiène',
		fasteners: 'Attaches',
		reports: 'Rapport | Rapports',
		notes: 'Notes',
		photos: 'Photos',
		noReports: 'Aucun rapport n’a été archivé pour cet article.',
		fileFirstReport: 'pour archiver le premier rapport pour cet article.',
		areYouSureDelete: 'Voulez-vous vraiment supprimer ce rapport ? Cette action ne peut pas être annulée.',
		deleteFailed: 'Le rapport n’a pas pu être supprimé.',
		viewImages: 'Visualiser les images'
	},

	textWithDatepicker: {
		title: 'Date de la prochaine inspection',
		lessYearWarning: 'La date de la prochaine inspection doit être dans moins d’un an.',
	},
	manageDepartments: {
		noOfAprons: 'Nbre d’articles',
		addDepartment: 'Ajouter un département',
		areYouSureDelete: 'Voulez-vous vraiment supprimer le département {0} ? Cela ne supprimera pas les articles affectés au département.',
		ownerWarning: 'Seul le « propriétaire » d’un établissement peut gérer les départements dans Ray iQ. Le propriétaire de votre établissement dans Ray iQ est {0}',
	},
	apronShortlist: {
		settings: 'Paramètres',
		status: 'État',
		sn: 'Référence',
		fabric: 'Tissu',
		ribbon: 'Liséré',
		user: 'Utilisateur',
		lastInspection: 'Dernière inspection',
		awayForRepairs: 'En réparation',
		noResults: 'Votre recherche n’a donné aucun résultat',
		defaultEmptyListText: 'Vous n’avez aucun article en stock.',
		fileReportForApron: 'Voulez-vous archiver un rapport pour cet article qui vient de revenir de réparation ?',
		inventoryNumber: "Numéro d'inventaire",
		nextInspection: 'Prochaine inspection'
	},
	searchBox: {
		placeholder: 'Recherche... ',
	},
	tableFilters: {
        filterHeading: "Filtrer L'inventaire",
		size: 'Taille',
		status: 'État',
		model: 'Modèle',
		manufacturer: 'Fabricant',
		importDate: 'Importé sur',
        nextInspection: 'Prochaine inspection',
        user: 'Utilisateur',
        department: 'Département',
        awayForRepairs: 'En réparation',
        monogram: 'Monogramme',
    },
	tableSettings: {
		columns: 'Colonnes',
		chooseColumns: 'Veuillez choisir lesquelles des colonnes optionnelles suivantes doivent être affichées/masquées :',
		tableSettings: 'Paramètres du tableau'
	},
	apronDetail: {
		sizeAdjustments: 'Ajustements de taille',
		leadEquivalence: 'Équivalence de plomb',
	},
	previewPlusDetails: {
		apronNotFound: 'Article introuvable. Veuillez {0}.',
		returnToDashboard: 'retourner au tableau de bord',
		fabricColorUnknown: 'Couleur de tissu inconnue',
		fabricAndRibbonColorUnknown: 'Couleur de tissu et de liséré inconnue',
		ribbonColorUnknown: 'Couleur de liséré inconnue',
		editDetails: 'Modifier details'
	},
	questionnaire: {
		veryBad: 'Très mauvais',
		bad: 'Mauvais',
		acceptable: 'Passable',
		good: 'Bon',
		veryGood: 'Très bon',
		protectiveQualitiesInstruction: 'Vérifier si les matériaux de protection présentent des défauts en utilisant le mode fluoroscopique d’un appareil à rayons X. La taille ainsi que l’emplacement du défaut sont importants. Sur la base des images radiographiques, vous pouvez décider d’éliminer, d’approuver ou de surveiller de près le tablier.',
		hygieneInstruction: 'Inspecter visuellement les vêtements et décider si l’article peut être correctement nettoyé. Vérifier très soigneusement le matériau extérieur pour voir dans quelle mesure il est abîmé. Inspecter également les zones difficiles à nettoyer, telles que les lisérés et les coutures.',
		fastenerInstruction: 'Vérifier si le velcro se fixe solidement et ne se détache pas trop facilement. Vérifier aussi que les coutures autour du velcro sont bien solides. Si le tablier a des boucles, vérifier qu’elles sont en bon état.',
	},
	quoteDetail: {
		sizing: 'Tailles',
		garmentSize: 'Taille du vêtement',
		customization: 'Personnalisation',

	},
	readMore: {
		readMore: 'Lire plus'
	},
	inviteColleague: {
		addAcolleague: 'Ajouter un(e) collègue',
		welcomeText: 'Inviter un(e) collègue à rejoindre votre établissement {0}.',
		accepted: 'Accepté',
		pending: 'En attente',
		ownerWarning: 'Seul le « propriétaire » d’un établissement peut inviter de nouveaux membres ou modifier les droits d’utilisateurs. Le propriétaire de votre établissement dans Ray iQ est {0}',
		invitationSent: 'L’invitation a été envoyée.',
		rights: 'Droits',
		editUserRights: 'Modifier les droits d’utilisateur',
		sendInvite: 'Envoyez l’invitation',
		areYouSureDelete: 'Êtes-vous sûr de vouloir supprimer l’utilisateur {0}?',
		owner: 'Propriétaire de l’institut',
	},
	accountConfig: {
		welcome: 'Bienvenue',
		thankYou: 'Merci de vous être inscrit(e). Pour vous permettre de tirer le meilleur parti de Ray iQ, cet assistant vous guidera dans la configuration de votre compte.',
		initialStatus: 'État initial des articles neufs',
		initialStatusQuestion: 'Voulez-vous effectuer une inspection de chaque article neuf saisi dans la base de données ? (Ce paramètre peut être modifié ultérieurement sur la page Paramètres.)',
		tolerances: 'Tolérances',
		toleranceInstruction: 'Veuillez définir la tolérance pour les performances de vos articles.',
		toleranceInfoMessage: 'Cela vous aidera à décider d’approuver ou non un article lors de la production de rapports. Chaque article qui obtient les valeurs sélectionnées (ou des valeurs supérieures) sera approuvé.',
		protectiveQualities: 'Qualités de protection',
		conditionFasteners: 'État des attaches',
		itemLimitReached: 'Vous avez atteint la limite de {0} articles que vous pouvez stocker dans votre inventaire.'
	},
	dashboardHome: {
		dashboard: 'Tableau de bord',
		numberOfItems: 'Nombre total d’articles',
		inspectionItems: 'Articles nécessitant une inspection',
		importItems: 'Articles prêts à être importés',
		inspectionListHeader: 'Articles nécessitant une inspection/un entretien',
		emptyInspectionList: 'Il n’y a actuellement aucun article sur lequel vous devez porter votre attention.',
		myInventory: 'Mon stock',
		noItemsWarning: 'Vous n’avez aucun article en stock. Veuillez utiliser le bouton Ajouter un article pour ajouter un article.',
		importScanflexTip: 'Veuillez utiliser le bouton Importer en haut à gauche pour importer vos achats Scanflex dans le stock.',
		import: 'Importer',
		accountConfig: 'Configuration du compte',
		editApron: 'Modifier un article',
		addApron: 'Ajouter un article',
		discardItems: 'Éliminer un ou plusieurs articles',
		importFailed: 'L’importation n’a pas pu être terminée. Veuillez nous contacter via info@ray-iq.com',
		inventoryLimitReached: "Limite d'inventaire atteinte !",
		bulkEditAprons: 'Modifier en lots',
	},
	privateMenu: {
		discardedItems: 'Articles éliminés',
		logOut: 'Se déconnecter',

	},
	productSelector: {
		selectProduct: 'Sélectionner un produit',
		frontProtection: 'Protection frontale',
		allRoundProtection: 'Protection complète',
		thyroidCollars: 'Protège-thyroïde',
		popularChoice: 'Article <br /> très <br /> prisé !',
		open: 'Ouvrir',
		areYouSureDeleteFavorite: 'Voulez-vous vraiment supprimer {0} des configurations favorites ?',
		added: 'Ajouté',
		colorScheme: 'Schéma de couleur',
		renameFavoriteTitle: "Renommer favori",
		renameFavorite: "Donnez un nouveau nom à votre configuration préférée",
		nameFavorite: "Donnez un nom à votre configuration préférée, enregistrez-la et retrouvez votre configuration dans la vue d'ensemble du produit.",
		enterName: 'Entrez un nom...'
	},
	reportPage: {
		outOfToleranceWarning: 'Votre {0} est hors tolérance',
		notesAndPhotos: 'Notes et photos',
		uploading: 'Téléchargement en cours... ',
		upload: 'Télécharger',
		fileRestirctions: 'JPG, PNG ou BMP. Maximum 5 photos et 4 MB par photo',
		currentFiles: 'Fichiers actuellement sélectionnés',
		noToleranceMessage: 'Vous devez définir des critères de tolérance avant de pouvoir enregistrer des rapports. Visitez {0} pour configurer les critères de tolérance.',
		discardApron: 'Éliminer l’article',
		outOfToleranceReminder: 'Cet article est hors tolérance pour les critères suivants',
		approveAnywayMessage: 'Ce résultat indique que l’article devrait être éliminé. S’il n’y a pas encore de remplacement, vous pouvez approuver cet article de toute façon pour le conserver dans votre stock jusqu’à ce que son remplacement devienne disponible.',
		keepInInventory: 'Conserver dans le stock',
		keptInInventoryMessage: 'Vous avez choisi de conserver cet article dans le stock bien qu’il soit hors tolérance. Il est conseillé de planifier une inspection dans un court délai.',
		scheduleNextInspection: 'Planifier la prochaine inspection',
		narrowestOfMargins: 'Cet article a été accepté avec une marge très étroite. Il est recommandé de surveiller de près cet article. Vous pouvez planifier la prochaine inspection ici.',
		surplusIgnored: 'Vous avez sélectionné plus de cinq photos. Le surplus ne sera pas téléchargé.',
		fileTooLarge: 'Le fichier « {0} » est trop volumineux et sera omis.',
		compressedArchves: 'Les archives compressées ne sont pas autorisées. Le fichier « {0} » sera omis.'
	},
	settings: {
		account: 'Compte',
		general: 'Généralités',
		currentPassword: 'Mot de passe actuel',
		newPassword: 'Nouveau mot de passe',
		confirmPassword: 'Confirmer le mot de passe',
		inspectionNeeded: 'Inspection nécessaire',
		afterRepairs: 'État des articles revenus de réparation',
		emailNotifications: 'Notifications par e-mail',
		inspectionReminders: 'Rappels pour l’inspection',
		newsUpdates: 'Dernières nouvelles (articles, etc.)',
		inviteColleague: 'Inviter un(e) collègue',
		passwordUpdateError: 'Votre mot de passe n’a pas pu être mis à jour pour la ou les raisons suivantes :',
		passwordsDontMatch: 'Le nouveau mot de passe et la confirmation ne correspondent pas.',
		passwordCriteria: 'Le mot de passe doit comporter au moins 8 caractères, doit contenir une lettre majuscule, un chiffre, une lettre minuscule et un caractère spécial ($!%*?&).',
		emailMayNotBeEmpty: 'Veuillez indiquer une adresse e-mail.',
		afasReminders: 'Rappels d’importation d’articles achetés',
		saveMessage: "Tous les paramètres (à l'exception des paramètres de notification) sont globaux. Celles-ci affectent l’ensemble de votre institution.",
		FiveThreePointScore: 'Système de notation en cinq points'
	},
	worklist: {
		worklist: 'Liste de travail',
		reportSaved: 'Rapport enregistré',
		reportSavedSuccessfully: 'Le rapport a été enregistré avec succès. Pour produire un autre rapport, veuillez sélectionner un autre article sur la liste de travail.',
		discardedMessage: 'Votre {0} est éliminé. Vous pouvez configurer un nouvel article dans le {1}',
		noItemsNeedAttention: 'Il n’y a actuellement aucun article sur lequel vous devez porter votre attention.'
	},
	passwordFields: {
		password: 'Mot de passe',
		fillPasswordPlease: 'Veuillez saisir un mot de passe'
	},
	confirmEmail: {
		confirmationFailed: 'Échec de la confirmation d’e-mail',
		contactUsToResolve: 'Votre e-mail n’a pas pu être confirmé. Veuillez nous contacter via info@ray-iq.com pour résoudre ce problème.',
		visitHomePage: 'Cliquer ici pour visiter la page d’accueil.',
		confirmingEmail: 'Confirmation de l’adresse e-mail',
		pleaseWaitConfirm: 'Veuillez patienter pendant que nous confirmons votre adresse e-mail.',
		confirmationSuccess: 'Adresse e-mail confirmée',
		redirectInProgress: 'Vous allez maintenant être redirigé(e) vers le formulaire de connexion.',
		forceRedirect: 'Veuillez cliquer ici si vous n’êtes pas redirigé(e) automatiquement.'
	},
	contact: {
		contactUs: 'Nous contacter',
		suggestionsAndIssues: 'Si vous avez des suggestions pour l’application, ou si vous rencontrez des problèmes, veuillez contacter :',
		salesAndLogistics: 'Ventes et Logistique',
		salesRelatedQuestions: 'Si vous avez des questions liées aux ventes <span class="color-red »>(pays du Benelux uniquement !)</span> veuillez contacter :',
		ourLocation: 'Notre emplacement',
		theNetherlands: 'Pays-Bas'
	},
	forgotPassword: {
		forgotPassword: 'Mot de passe oublié',
		enterEmail: 'Veuillez saisir votre adresse e-mail pour générer un nouveau mot de passe.',
		checkEmailMessage: 'Veuillez vérifier votre e-mail et cliquer sur le lien pour réinitialiser votre mot de passe.'
	},
	landingspage: {
		intro: "Ray iQ est une application Web développée par Cablon Medical. Ray iQ peut vous aider en facilitant la gestion des stocks, et vous permet de créer des rapports d'assurance qualité. Ray iQ vous envoie automatiquement des rappels pour les contrôles annuels, et des rapports de ces contrôles sont facilement établis par article. Il n'est plus nécessaire d'utiliser des feuilles Excel volumineuses et complexes. Il est également possible de composer des articles plombés dans le configurateur de l'application, pour voir à quoi ils ressembleront.",
		KeepTrackTitle: 'Assurez le suivi de vos tabliers plombés',
		HowToGetStarted: 'Pour commencer',
		packedWithFeatures: 'De nombreuses fonctionnalités',
		easyReporting: 'Facilité de production des rapports',
		automaticReminders: 'Rappels automatiques',
		apronConfigurator: 'Configurateur de tabliers',
		rayiqAvailabilityMessage: 'Ray iQ est disponible pour tous les clients de Cablon Medical ayant signé des accords exclusifs pour l’achat de tabliers et d’accessoires plombés Scanflex. Vous êtes intéressé(e) ? Contactez votre responsable de comptes ou Cablon Medical.',
		call: 'Appel',
		mail: 'Courier',
		tryConfigurator: 'Essayer le configurateur gratuitement',
		rayiqExplained: 'Ray iQ expliqué',
		easyImport: 'Importation facile',
		upToDateInventory: 'Inventaire à jour',
		easyImportText: 'All your purchased Scanflex items are automatically included in Ray iQ',
		easyReportingText: 'Simple reporting with adjustable rejection criteria for annual checks',
		automataticRemindersText: 'Automatic reminders for annual checks',
		apronConfiguratorText: 'Compose new clothing and request quotes in the configurator',
		upToDateInventoryText: 'Up-to-date overview of all your radiation protection items'
	},
	login: {
		login: 'Se connecter',
		register: 'S’inscrire',
		createAccount: 'Créer un compte',
		forgotPassword: 'Mot de passe oublié ?'
	},
	publicMenu: {
		toDashboard: 'Tableau de bord',
	},
	resetPassword: {
		resetPassword: 'Réinitialiser le mot de passe',
		enterPassword: 'Veuillez saisir le nouveau mot de passe que vous voulez utiliser pour votre compte.',
		resetFailed: 'Échec de la réinitialisation du mot de passe',
		resetFailedExplanation: 'Votre mot de passe n’a pas pu être réinitialisé. Remarque : Les liens de réinitialisation de mot de passe ne peuvent être utilisés qu’une seule fois.',
		clickHereTryAgain: 'Veuillez cliquer ici pour réessayer.',
		resetSuccessful: 'Réinitialisation du mot de passe réussie',
		loginAndRedirectPending: 'Vous allez maintenant être connecté(e) et redirigé(e) vers le tableau de bord.'
	},
	discardedItems: {
		backInInventory: 'Retransférer vers stock'
	},
	importFromExcel: {
		title: 'Import d’articles depuis modèle Excel',
		stepOne: 'Téléchargez le modèle et introduisez-y vos données. Si vous avez déjà un modèle valide avec des données, cliquez sur Suivant.',
		downloadTemplate: 'Télécharger un modèle',
		stepTwo: 'Déposez le modèle Excel dans la zone ci-dessous ou cliquez sur le bouton de téléchargement pour sélectionner le modèle Excel depuis votre appareil',
		dropTemplate: 'Déposez le modèle ici',
		acceptedFiles: '(Fichiers XLS ou XLSX uniquement. Taille maximale de fichier : 4 Mo)',
		tooManyFiles: 'Vous ne pouvez télécharger qu’un seul fichier à la fois',
		nonValidExtention: 'Fichier non valide',
		fileTooLarge: 'Le fichier {0} est trop gros. Veuillez télécharger les données en plus petits lots.',
		releaseMouseBtn: 'Relâchez le bouton de la souris pour déposer le modèle',
		processingExcel: 'Traitement Excel en cours',
		maxRows: 'Jusqu’à 1000 lignes peuvent être traitées à la fois',
	},
	compareImportedData: {
		title: 'Import Excel',
		manufacturerConverted: 'Fabricant (converti)',
		modelConverted: 'Modèle (converti)',
		addToInventory: 'Ajouter à l’inventaire',
		errors: 'Incomplet',
		converted: 'Nécessite vérification',
		changeManufacturerName: 'Voulez-vous modifier tous les articles dénommés {0} à {1} ?',
		itemSkipped: 'Article{n} ignoré, vu que le numéro de série existait déjà | {n} articles ignorés, vu que leur numéro de série existait déjà',
		itemDuplicate: 'Entrée en double {n} ignorée dans la feuille Excel | {n} entrées en double ignorées dans la feuille Excel',
		largeExcel: 'Article {n} ignoré, car le fichier Excel téléchargé dépasse 1000 lignes | {n} articles ignorés, car le fichier Excel téléchargé dépasse 1000 lignes.',
		addingItems: 'Ajout d’articles à votre inventaire.Veuillez ne pas fermer cette fenêtre.',
		addingComplete: 'Les articles ont été ajoutés à votre inventaire. Vous pouvez les visualiser sur votre tableau de bord. Vous serez redirigé vers le tableau de bord dans quelques secondes.',
		errorsDuringAdd: '{a} articles ont été ajoutés à votre inventaire. {n} article n’a pas pu être ajouté. Veuillez réessayer. Si vous continuez à avoir des problèmes, veuillez nous contacter via | {a} articles ont été ajoutés à votre inventaire. {n} articles n’ont pas pu être ajoutés. Veuillez réessayer. Si vous continuez à avoir des problèmes, veuillez nous contacter via ',
		limitExceeded: "{0} articles ignorés, car la limite d'inventaire est atteinte"
	},
	cookieNotice: {
		message: 'En vous connectant, vous acceptez les cookies fonctionnels.',
	},
	departmentRights: {
		rightsDescription: 'Lorsque vous avez attribué des articles de radioprotection à des services, vous pouvez déterminer par utilisateur s’il est autorisé à voir les articles de ce(s) service(s). Utilisez les coches ci-dessous pour indiquer à quels départements l’utilisateur a accès.'
	},
	inviteRights: {
		atLeastOneDept: 'Vous devez accorder des droits à au moins un service.',
		emailColleague: 'Adresse e-mail d’un collègue'
	},
	pageNotFound: {
		title: " Page non trouvée...",
		message: "Désolé, la page que vous recherchez n'existe pas ou a été déplacée.",
		buttonText: "Aller à la page d'accueil"
	},
	freeTextInput: {
		errorMessage: 'Ce champ ne peut pas rester vide',
	},
	testimonials: {
		title: "Ce qu'en disent d'autres personnes",
		functionTitle0: 'Head of Radiation Physics & Health Physics',
		functionTitle1: 'Expert superviseur en matière de radiation',
		testimonial0: `Par rapport à notre ancienne liste d'inventaire, simple, l'application Ray iQ nous présente un tableau de bord clair avec une vue d'ensemble de tous les équipements de radioprotection, ainsi qu'une liste séparée des articles qui doivent être inspectés. L'approche graphique et la facilité d'utilisation ont permis une transition fluide vers cette nouvelle application. Notre organisation considère l'application comme une nette amélioration, entre autres pour les raisons suivantes : Chaque département peut visualiser sa propre liste d'articles, tandis que le responsable conserve une vue d'ensemble de tous les articles de l'organisation. Les critères de réussite/échec sont configurables par organisation. Dans le configurateur, il est possible de créer des configurations des tenues plébiscitées, de sorte que chaque département commande la même couleur de tissu pour la même taille de vêtement. Cela permet de gagner du temps lors du choix du bon vêtement de radioprotection. L'application génère un historique des rapports pour chaque période, <mark>fournissant ainsi un aperçu de l'usure de l'article au fil du temps, et standardise la prise de décision lors de la mise au rebut des articles.</mark> L'e-mail mensuel donnant une vue globale des éléments à inspecter est jugé utile.`,
		testimonial1: "Nous utilisons Ray iQ pour les inspections de nos vêtements de radioprotection. Quelle facilité ! Tous les articles sont affiches dans une base de données claire et, <mark>en quelques clics, un rapport d'inspection est établi</mark>, recommandant de conserver ou de rejeter un article. les articles rjetjes peubent être remplacés en un instant, en passant commande de nouveaux tabliers à l'aide du configuratuer. Au final, une belle amélioration grâce à Ray iQ.",
	}
}
